/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";

// image
import ocenBluegel from "../../assets/categories/new/ocenBluegel.jpeg";
import lemonBodyWash from "../../assets/categories/new/lemon-body-wash.jpeg";
// import aloevera from "../../assets/categories/new/alw.jpeg";
import antiAcne from "../../assets/categories/new/anti-acne-facewash.jpeg";
// import LaceFabric from "../../assets/categories/new/01.jpg";
import iceWipes from "../../assets/categories/new/icefresh-wipes.jpeg";
import honeyWipes from "../../assets/categories/new/honey-wipes.jpeg";
// import lemonWipes from "../../assets/categories/new/lemon-wipes.jpeg";
import LameFabric from "../../assets/banners/new/05.jpg";
// import babyWash from "../../assets/banners/new/06.jpg";
// import sanityPad from "../../assets/banners/new/44 (1).jpg";
// import Slider from "react-slick";
import sanitaryNapkin from "../../assets/icons/sanitary-napkin.png";
import wipes from "../../assets/icons/wipes.png";
import lotion from "../../assets/icons/lotion.png";
import liquidSoap from "../../assets/icons/liquid-soap.png";
import babyBoy from "../../assets/icons/baby-boy.png";
import PH from "../../assets/icons/ph-balance.png";
import noDrinks from "../../assets/icons/no-drinks.png";
import parabenFree from "../../assets/icons/atom.png";
import soft from "../../assets/icons/feather.png";
import fragrance from "../../assets/icons/natural.png";
import extraction from "../../assets/icons/extraction.png";
import safe from "../../assets/icons/GMP.jpg";
import fda from "../../assets/icons/ISO.jpg";
import tested from "../../assets/icons/tested.png";
import home1 from "../../assets/home/01.jpg";
import home2 from "../../assets/home/02.jpeg";
import Testimonial from "./testimonial";
import { Grid, Typography, Button, Box } from '@mui/material';
function Categories() {
  const navigate = useNavigate();
  const handleMouseEnter = (e) => {
    e.target.play();
  };

  const handleMouseLeave = (e) => {
    e.target.pause();
    // e.target.currentTime = 0;
  };
  const categories = [
    { img: liquidSoap, name: "Body Wash" },
    { img: sanitaryNapkin, name: "Sanitary Pads" },
    { img: lotion, name: "FaceWash" },
    { img: babyBoy, name: "Baby Products" },
    { img: wipes, name: "Wipes" },
  ];
  const bestSeller = [
    { img: ocenBluegel, name: "Ocean Blue Body Wash" },
    { img: lemonBodyWash, name: "Lemon Body Wash" },
    { img: antiAcne, name: "Anti Acne Facewash" },
    { img: honeyWipes, name: "Honey Almond Wipes" },
    { img: iceWipes, name: "Ice Fresh Wipes" },
    { img: LameFabric, name: "Baby Powder" },
  ];
  const standards = [
    {
      img: tested,
      name: "Dermatologically Tested",
      desc: "We ensure each product is tested clinically on the sensitive human skin to ensure that it is not allergic.",
    },
    {
      img: fda,
      name: "ISO Certified",
      desc: "Lieve is ISO Certified, which means we are 100% safe to be used for babies .",
    },
    {
      img: safe,
      name: "Good manufacturing practice",
      desc: "Good manufacturing practice (GMP) is a system for ensuring that products are consistently produced and controlled according to quality standards",
    },
    // {
    //   img: safe,
    //   name: "Made Safe Certified",
    //   desc: "The MADE SAFE (Made with Safe Ingredients™) seal means that a product is literally made with safe ingredients.",
    // },
  ];
  const [data, setData] = useState([
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/WhatsApp%20Video%202023-09-17%20at%2012.05.24%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C6ne5Sgtmix/?igsh=dGhhbmdqdXV1MnJr",
      embedUrl: "https://www.instagram.com/reel/C6ne5Sgtmix/embed",
      url: "LEMON FACIAL WIPES",
      id: "65b73db6a058b07e02163fed",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Lieve%20facewash%20reel%2001%203%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C6tKDkHPLKT/?igsh=MXVuc2dramd3Z25obw==",

      url: "ALOE VERA FACE WASH",
      id: "65ba3afb7c609919e992f49d",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Bodywash%20reel%2001%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C7TLTvGhsl7/?igsh=emYwZmhjdGFucG9t",

      url: "OCEAN BLUE BODY WASH",
      id: "65b5ef5031d1fdf808361a33",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Gel%20reel%2001%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C621PMwPBHo/?igsh=MTZ5NXFsb2p3MHBnZA==",

      url: "ALOE VERA GEL",
      id: "65ba4559daa43a376f005714",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/Lieve%20Babywash%20reel%2001%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C7g_uoxB5Vp/?igsh=dmowdXh1ZDl2ejln",

      url: "BABY BODY WASH",
      id: "65ba47addaa43a376f00579b",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/lieves%20BABY%20POWDER%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C7oJRbFMvkv/?igsh=NWxndGMxNWRsZDVl",

      url: "BABY POWDER",
      id: "65ba497bdaa43a376f00583b",
    },
    {
      videoBucket:
        "https://storage.googleapis.com/lieve-bucket/home-videos/IMG_8715%20(1).mp4",
      video:
        "https://www.instagram.com/reel/C5SccqdRaes/?igsh=eDNia280aTFyNDV5",

      url: "UBTAN FACE WASH",
      id: "65ba4193daa43a376f0056bd",
    },
  ]);
  const feature = [
    {
      img: PH,
      name: "pH-balanced",
      desc: "Maintains your skin’s natural acidity for healthy, irritation-free skin.",
    },
    {
      img: noDrinks,
      name: "Alcohol-free",
      desc: "Preserves your skin’s natural moisture with a gentle, alcohol-free formula.",
    },
    {
      img: parabenFree,
      name: "Paraben-Free",
      desc: "Safeguards your skin by avoiding preservatives that may cause irritation.",
    },
    {
      img: soft,
      name: "Soft Texture",
      desc: "Delivers a luxuriously smooth feel for a gentle touch on your skin.",
    },
    {
      img: fragrance,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: extraction,
      name: "Natural Extracts",
      desc: "Infuses your skincare with the benefits of pure, plant-based ingredients.",
    },
  ];
  const news = [
    {
      img: ocenBluegel,
      name: "pH-balanced",
      desc: "Maintains your skin’s natural acidity for healthy, irritation-free skin.",
    },
    {
      img: ocenBluegel,
      name: "Alcohol-free",
      desc: "Preserves your skin’s natural moisture with a gentle, alcohol-free formula.",
    },
    {
      img: LameFabric,
      name: "Paraben-Free",
      desc: "Safeguards your skin by avoiding preservatives that may cause irritation.",
    },
    {
      img: iceWipes,
      name: "Soft Texture",
      desc: "Delivers a luxuriously smooth feel for a gentle touch on your skin.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: honeyWipes,
      name: "Natural Fragrance",
      desc: "Offers a subtle, pleasant aroma derived from natural essential oils.",
    },
    {
      img: antiAcne,
      name: "Natural Extracts",
      desc: "Infuses your skincare with the benefits of pure, plant-based ingredients.",
    },
  ];

  return (
    <section className="categories-grid pt-50">
      <div className="container">
        <div className="section-heading">
          <h2>PRODUCT CATEGORIES</h2>
        </div>
        <div className="categories-icon">
          {categories?.map((obj) => {
            return (
              <div className="categories-block">
                <div
                  onClick={() => navigate("/products")}
                  className="categories-block-icon"
                >
                  <img src={obj?.img} alt="icon" />
                </div>
                <p>{obj?.name}</p>
              </div>
            );
          })}
        </div>
        <div onClick={() => navigate("/products")} className="explore-all">
          Explore Products
          <div className="explore-all-arrow">
            <GoArrowUpRight className="explore-all-arrow-icon" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section-heading">
          <h2>BEST SELLERS</h2>
        </div>
        <div className="bestseller">
          {bestSeller?.map((obj, index) => {
            // const isOdd = index % 2 !== 0;
            return (
              // isOdd ? (
              <div className="bestseller-block up-radius">
                <div className="bestseller-block-icon">
                  <img src={obj?.img} alt="icon" />
                </div>
                <div className="bestseller-content">
                  <p>{obj?.name}</p>
                  <div
                    onClick={() => navigate("/products")}
                    className="explore-all-arrow"
                  >
                    <GoArrowUpRight className="explore-all-arrow-icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="container">
        <Box className="standards-container">
          {/* First Section: Image on the left, content on the right */}
          <Grid container spacing={2} sx={{ padding: '24px' }} alignItems="center">
            {/* Left side image */}
            <Grid item xs={12} md={4} alignItems="center">
              <Box component="img"
                src={home1}
                alt="Product 1"
                sx={{ width: '80%', height: 'auto' ,margin:'auto'}} />
            </Grid>
            {/* Right side content */}
            <Grid item xs={12} md={8}>
              <h2>
                FACE WASH
              </h2>
              <div className="standards-text1">
              <Typography variant="body1" paragraph>
                Lieve Using an aloe vera face wash works for all skin types because it contains antibacterial, anti-inflammatory, and healing properties. It helps reverse the damage caused by pollution and UV rays. Aloe Vera deeply hydrates the skin and provides a soothing effect on the skin without making it greasy.   </Typography>
             </div> <Button variant="contained" onClick={() => navigate('product/ALOE VERA FACE WASH')} style={{background:'#000'}}>
                Shop Now
              </Button>
            </Grid>
          </Grid>

          {/* Second Section: Image on the right, content on the left */}
          <Grid container spacing={2} sx={{ padding: '24px', backgroundColor: '#f5f5f5' }} alignItems="center">
            {/* Right side image */}
            <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
              <h2>
                FACIAL WIPES

              </h2>
              {/* <div className={`news-heading `}>
                <h2>FACIAL WIPES</h2>
                </div> */}
              <div className="standards-text1">
              <Typography variant="body1" paragraph>
                Lieve Wet wipes for face use are to remove dirt, oil, and makeup from the skin. They create a clean slate for the rest of the skincare products to be absorbed by their skin. This can help to ensure that one’s other skincare products are working at their full potential, providing the maximum benefits for their skin.  </Typography>
              </div><Button variant="contained" onClick={() => navigate('product/LEMON FACIAL WIPES')} style={{ background: '#000' }}>
                Shop Now
              </Button>
            </Grid>
         
            {/* Left side content */}
            <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }} alignItems="center">
              <Box component="img"
                src={home2}
                alt="Product 2"
                sx={{ width: '80%', height: 'auto',margin:'auto',float:{md:"right",xs:"left"} }} />
            </Grid>
          </Grid>
          {/* Third Section: Image on the left, content on the right */}
          <Grid container spacing={2} sx={{ padding: '24px',display:'none' }} alignItems="center">
            {/* Left side image */}
            <Grid item xs={12} md={6}>
              <Box component="img"
                src={bestSeller[0].img}
                alt="Product 1"
                sx={{ width: '50%', height: 'auto' }} />
            </Grid>
            {/* Right side content */}
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Product Title 1
              </Typography>
              <Typography variant="body1" paragraph>
                This is a brief description of the product. Highlight the key features and benefits.
              </Typography>
              <Button variant="contained" color="primary">
                Shop Now
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="container news">
        <div className="news-container">
          <div className={`news-heading `}>
            <h5>Discover</h5>
            <h2>Our Story</h2>
            <p>
              we believe that true wellness begins with compassionate care and
              personalized attention. Our journey started with a simple idea to
              create a space where health and well-being are not just services,
              but an experience of comfort, trust, and expert guidance.
            </p>
          </div>

          <div className="news-scroll-container">
            <div className="news-container-div">
              {data?.map((obj, index) => (
                <div
                  className="news-block"
                  key={index}
                  onClick={() => window.open(obj?.video, "_blank")}
                >
                  <video
                    // autoPlay
                    muted
                    loop
                    width={"100%"}
                    style={{
                      objectFit: "cover",
                    }}
                    className="img"
                    preload="auto"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <source src={obj?.videoBucket} type="video/mp4" />
                  </video>

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/product/${obj?.url}`);
                    }}
                    class="glow-on-hover"
                  >
                    Shop now!
                  </button>
                  {/* <p onClick={() => navigate(`/product/${obj?.url}`)}>
                    Shop now!
                  </p> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="standards-container">
          <h3>Super Safe Standards</h3>
          <div className="standards">
            {standards?.map((obj, index) => {
              return (
                <div className="standards-block">
                  <img src={obj?.img} alt="" />
                  <div className="standards-text">
                    <h5>{obj?.name}</h5>
                    <p>{obj?.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="natural-container">
          <div className="natural-heading">
            <h2>Experience Ultimate Natural Beauty</h2>
          </div>{" "}
          <div className="bg-image"></div>
          <div className="feature-container-div">
            <div className="feature-container">
              {feature?.map((obj, index) => {
                const isOdd = index % 2 !== 0;

                return !isOdd ? (
                  <div className="feature-block">
                    <img src={obj?.img} alt="" />
                    <div>
                      <h5>{obj?.name}</h5>
                      <h6>{obj?.desc}</h6>
                    </div>
                  </div>
                ) : (
                  <div className="feature-block">
                    <div>
                      <h5>{obj?.name}</h5>
                      <h6>{obj?.desc}</h6>
                    </div>
                    <img src={obj?.img} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        <Testimonial />
      </div>
    </section>
  );
}

export default Categories;
